<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="col-md-4 ml-5 mb-10">
        <h6>{{ group.name }}</h6>
      </div>
      <div
        style="
    position: sticky;
    top: 99px;
    z-index: 9;
    background: white;
"
      >
        <div class="col-md-12" style="background:white">
          <div class="col-md-6 col-sm-6">
            <multiselect
              :key="1"
              :options="serviceservices"
              placeholder="Select Service "
              label="name"
              :custom-label="nameWithLang"
              track-by="id"
              @input="draggableArray"
            ></multiselect>
          </div>
          <div
            class="col-md-6"
            style="margin-top: -4%;
    margin-bottom: 4%;
    float: right;"
          >
            <button
              v-if="services.length > 0"
              ref="kt_update_note"
              type="reset"
              class="btn btn-success mr-2"
              style="height: 36px;float: right;"
              @click="storeUpdate()"
            >
              Update
            </button>
            <button
              ref="kt_update_note"
              type="reset"
              class="btn btn-danger mr-3"
              style="height: 36px;float: right;"
              @click="goback($router.go(-1))"
            >
              <i class="fa fa-arrow-left"></i>
              Go back
            </button>
          </div>
        </div>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th scope="col">Service Name</th>
            <th scope="col">Status</th>
            <th scope="col">Drug Testing</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <draggable v-model="services" tag="tbody">
          <tr v-for="(item, index) in services" :key="item.name">
            <td>
              <i class="la la-arrows-v" aria-hidden="true"></i>
            </td>
            <td>{{ item.name }}</td>
            <td>
              <i class="fa fa-times text-danger" v-if="!item.is_active"></i>
              <i class="fa fa-check text-success" v-if="item.is_active"></i>
            </td>
            <td>
              <i
                class="fa fa-times text-danger"
                v-if="!item.is_drug_testing"
              ></i>
              <i
                class="fa fa-check text-success"
                v-if="item.is_drug_testing"
              ></i>
            </td>
            <td>
              <button class="btn btn-danger btn-sm" @click="deleteItem(index)">
                Remove
              </button>
            </td>
          </tr>
        </draggable>
      </table>
      <!-- <button class="btn btn-success" @click="storeUpdate">Update</button> -->
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import {
  FETCH_B_SERVICES,
  CREATE_GROUP_SERVICE,
  GET_GROUP
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import draggable from "vuedraggable";

export default {
  computed: {
    ...mapGetters(["currentUser"])
  },
  mounted() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(FETCH_B_SERVICES).then(res => {
      this.serviceservices = res.data;
    });
    this.$store.dispatch(GET_GROUP, this.$route.params.id).then(res => {
      this.services = res.services;
      this.group = res.group;
    });
  },
  components: {
    Multiselect,
    draggable
  },
  methods: {
    nameWithLang({ name }) {
      return `${name}`;
    },
    draggableArray(val) {
      const result = this.services.filter(w => {
        if (w.id == val.id) {
          return w;
        }
      });
      if (result.length > 0) {
        this.$toastr.w("Service Already added");
      } else {
        this.services.push({ ...val });
      }
    },
    deleteItem(i) {
      this.services.splice(i, 1);
    },
    storeUpdate() {
      let data = {
        id: this.$route.params.id,
        data: this.services
      };
      this.$store
        .dispatch(CREATE_GROUP_SERVICE, data)
        .then(() => {
          this.$toastr.s("Service added successfully");
          this.$store.dispatch(GET_GROUP, this.$route.params.id).then(res => {
            this.services = res.services;
            this.group = res.group;
          });
        })
        .catch(() => {
          this.$toastr.e("oops something went wrong !");
        });
    }
  },
  data() {
    return {
      loadingData: false,
      services: [],
      group: "",
      group_service: [],
      serviceservices: [],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Group List",
          route: "client.groups"
        },
        {
          id: 3,
          title: "Group Service",
          route: ""
        }
      ]
    };
  }
};
</script>
